import { useNavigation } from '@/app/hooks'
import { Button, LoadingAnimation, Text } from '@/components'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { Clock3Icon, FrownIcon } from 'lucide-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import confetti from 'canvas-confetti'
import { sanitizeProgramName } from '@/types/utilityProgram/utils'

export type EnrollmentState = 'pending' | 'error'
export default function EnrollmentState() {
  const navigate = useNavigation()
  const { id: utilityProgramId, state } = useParams<{
    id: string
    state: EnrollmentState
  }>()
  const { data: utilityPrograms, isLoading } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]
  const content = useMemo(
    () =>
      match(state)
        .with('pending', () => (
          <PendingState programName={utilityProgram.name} />
        ))
        .with('error', () => <ErrorState programName={utilityProgram.name} />)
        .exhaustive(),
    [utilityProgram],
  )
  const loadingAnimation = useMemo(
    () => (
      <div className={'flex justify-center items-center h-full'}>
        <LoadingAnimation type={'falling'} />
      </div>
    ),
    [],
  )
  if (!utilityProgram && !isLoading) {
    return loadingAnimation
  }
  return (
    <div className="flex h-full w-full flex-col gap-8 [&>*]:text-center justify-center">
      {content}
      <Button id={'continue'} onClick={() => navigate.push('/devices')}>
        Continue
      </Button>
    </div>
  )
}

function PendingState({ programName }: { programName: string }) {
  useEffect(() => {
    // Left side confetti
    confetti({
      particleCount: 100,
      spread: 70,
      startVelocity: 60,
      origin: { x: 0.1, y: 0.9 },
    })
    // Right side confetti
    confetti({
      particleCount: 100,
      spread: 70,
      startVelocity: 60,
      origin: { x: 0.9, y: 0.9 },
    })

    return () => {
      // necessary so confetti doesn't launch on random un/re-mounts
      confetti.reset()
    }
  }, [])

  return (
    <>
      <Clock3Icon
        className="text-themed-success rounded-full mx-auto"
        size={40}
      />
      <Text variant="h3">
        Great! You successfully applied for the{' '}
        {sanitizeProgramName(programName)} program.
      </Text>
      <Text variant="h3">
        We will be in touch about your application in about 5-8 business days.
      </Text>
      <Text variant="h3">
        Optiwatt is not in charge of approvals, our utility partner is.
      </Text>
    </>
  )
}

function ErrorState({ programName }: { programName: string }) {
  return (
    <>
      <FrownIcon className="text-themed-error rounded-full mx-auto" size={40} />
      <Text variant="h3">
        Oh no! You were not successfully enrolled in the{' '}
        {sanitizeProgramName(programName)} program
      </Text>
      <Text variant="h3">Please try again later.</Text>
    </>
  )
}
